define([
	"jquery", 
	"OUtils",
	"dwr/interface/UserRpc",
	"lib/detect-browser"
	], 
function(jquery, OUtils, UserRpc, DetectBrowser) { "use strict";
	var USERNAME_COOKIE_KEY = "oceanUsername";
	var urlVars = OUtils.getURLVars();
	var usernameInCookie = OUtils.getCookie(USERNAME_COOKIE_KEY);
	var username = urlVars["username"];//first check the URL to see whether the username was passed from the EMR
	var regionalSsoUserName = urlVars["regionalSsoUserName"];
	var emailValidated = urlVars["emailValidated"];

	var invitation = urlVars["invitation"];
	var siteNum = urlVars["siteNum"];
	var token = urlVars["token"];
	var email = urlVars["email"];

	var loginMain;

	function initLoginPage() {
		loginMain = $("#loginMain");
		redirectIfInIFrame();
		initLanguage();
		initLoginForm();
		initOneID();
		initSmartOnFhir();
		initTwoFactor();
		
		function redirectIfInIFrame() {
			if (window.top.location != window.location) {
				window.top.location.pathname = "/";
			}
		}
		function initLanguage() {
			var currentLanguageBtn = loginMain.find("#currentLanguage");
			if (OUtils.getCookie('i18next')) {
				// user preference set in cookie => checks if cookie exists, else sets to browser locale
				currentLanguageBtn.text(
					i18n.detectLanguage().startsWith("en") ? "Français" : "English"
				);

				currentLanguageBtn.click(function (e) {
					updateLanguageBtn(currentLanguageBtn, currentLanguageBtn.text(), function () {
						location.reload();
					});
				}).show();
			}
		}
		function updateLanguageBtn(currentLanguageBtn, currentLanguage) {
			if (currentLanguage === "English") {
				currentLanguageBtn.text("Français");
				i18n.setLng("en");
			} else {
				currentLanguageBtn.text("English");
				i18n.setLng("fr");
			}
			location.reload();
		}
		function initLoginForm() {
			var form = loginMain.find("#loginForm");

			var submitBtn = form.find("#submitBtn").click(function() {
				OUtils.showWait();
				loginMain.findIt("#oneIDLinkAccountsPanel").hide();
				submitForm();
			});
			OUtils.onEnter(form, function() {
				submitBtn.click();
			});
			
			if (username) {
				OUtils.setCookie(USERNAME_COOKIE_KEY, username);
			} else {
				//no username in URL; use the cookie if it has it stored
				username = usernameInCookie;
			}
			if (username && username.length > 0) {
				form.findIt("#username").val(username);
				form.findIt("#userPassword").focus();
			} else {
				form.findIt("#username").focus();
			}
			if (emailValidated) {
				loginMain.findIt("#successLabel").text(OUtils.t("Thank you for validating your email, you may now login to your Ocean account below.", "Login.js.email_validated_thank_you")).show();
			}
			var msg = OUtils.getLocalStorage("LOGIN_MSG");
			if (msg) {
				OUtils.setLocalStorage("LOGIN_MSG", null);
				loginMain.findIt("#infoLabel").text(msg).show();
			}
			var signUpHref = "/public/signup.html?linkFrom=portal";
			if (regionalSsoUserName) {
				signUpHref += "&regionalSsoUserName="+regionalSsoUserName;
			}
			if (invitation) {
				signUpHref += "&siteNum=" + siteNum + "&email=" + email + "&invitation=true&linkFrom=invitation&token=" + token;
			}
			form.findIt("#signUpLink").attr("href", signUpHref);
		}
		function initOneID() {
			var oneIdUserName = urlVars["regionalSsoUserName"];
			if (oneIdUserName) {
				loginMain.findIt("#oneIDLinkAccountsPanel").show();
			} else {
				loginMain.findIt("#provincialSignInPanel").show();
			}
		}
		function initSmartOnFhir() {
			var showIt = urlVars["smartLaunch"];
			if (showIt) {
				loginMain.findIt("#smartOnFhirLinkAccountsPanel").show();
			}
		}
		function initTwoFactor() {
			var twoFactorPanel = loginMain.findIt("#twoFactorAuthPanel");
			var deviceName = twoFactorPanel.findIt("#deviceName");
			deviceName.text(getDeviceId());
			var submitTwoFactorBtn = twoFactorPanel.findIt("#submitAuthCodeBtn");
			submitTwoFactorBtn.click(function() {
				var authCode = twoFactorPanel.findIt("#authCode").val();
				var rememberDevice = twoFactorPanel.findIt("#rememberDevice").is(":checked");
				if (authCode !== "") {
					submitWithAuthCode(authCode, rememberDevice);
				} else {
					twoFactorPanel.findIt("#authCodeFailedLabel").show();
				}
			});
			OUtils.onEnter(twoFactorPanel, function() {
				submitTwoFactorBtn.click();
			});
			twoFactorPanel.findIt("#emailCodeBtn").click(function() {
				loginMain.find(".alert").hide();
				loginMain.find("#emailInfo").show();
				UserRpc.generateEmailSecretForLogin(loginMain.findIt("#username").val(),
					loginMain.findIt("#userPassword").val(), {
						callback: function() {
							authCodeEmailSent();
						},
						errorHandler: function(msg, exception) {
							twoFactorPanel.findIt("#emailInfo").hide();
							twoFactorPanel.findIt("#authCodeErrorLabel").text(OUtils.t("Failed to send authentication code email. Please try again or contact OceanMD support.", "Login.js.auth_code_email_failed")).show();
						}
					});
			});
			function authCodeEmailSent() {
				twoFactorPanel.findIt("#authCodeLabel").hide();
				twoFactorPanel.findIt("#authCodeEmailLabel").show();
				twoFactorPanel.findIt("#emailCodeSpinner").hide();
				twoFactorPanel.findIt("#emailSent").show();
				twoFactorPanel.findIt("#emailCodeSection").hide();
			}
		}
		function showTwoFactorPanel() {
			loginMain.findIt("#mainLoginPanel").hide();
			loginMain.findIt("#provincialSignInPanel").hide();
			loginMain.findIt("#twoFactorAuthPanel").show();
			loginMain.findIt("#authCode").val("").focus();
		}
		function completeSiteInvitation(msg) {
			// Add user to the new site, then send them to new site
			UserRpc.completeSiteInvitation(token, siteNum, {
				callback: function() {
					var newSiteUrl = msg + "?siteNum=" + siteNum;
					window.location.replace(newSiteUrl);
				},
				errorHandler: function(err, exc) {
					OUtils.showError(OUtils.t("This site invitation is no longer valid. Please contact your site administrator.", "Login.js.site_invitation_invalid"),
						function() {
							window.location.replace(cachedRequestUrl);
						}, {title: OUtils.t("Invalid Site Invitation", "Login.js.invalid_site_invitation")});
				}
			});
		}
		function postLogin(postData, errorCallback) {
			// add additional parameters if signup token used

			function loginSuccess(msg) {
				var cachedRequestUrl = msg;
				var anchor = OUtils.getAnchorTag();
				if (anchor) {
					cachedRequestUrl += '#' + anchor;
				}
				if (invitation) {
					UserRpc.userMustSetUpMfa(siteNum, function(shoudSetupMfa) {
						if (shoudSetupMfa) {
							window.location.replace("/user/TwoFactorAuthPage.html?siteNum=" + siteNum + "&token=" + token);
						} else {
							completeSiteInvitation(msg);
						}
					});
				} else {
					window.location.replace(cachedRequestUrl);
				}
			}
			loginMain.find(".alert").hide();
			$.ajax({
				url: "/login",
				method: "POST",
				data: postData,
				success: function (msg) {
					loginSuccess(msg);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					errorCallback(jqXHR, textStatus, errorThrown);
				}
			});
		}
		function submitForm() {
			var form = loginMain.find("#loginForm");

			if (loginMain.findIt("#rememberUser").is(":checked")) {
				OUtils.setCookie(USERNAME_COOKIE_KEY, form.findIt("#username").val());
			}
			OUtils.setLocalStorage("usePKeyCache", false);
			var postData = $(form[0]).find("input").serialize();

			function loginError(jqXHR, textStatus, errorThrown) {
				var msg = jqXHR.responseText;
				if (jqXHR.status === 423) {
					// account locked
					var lockLbl = loginMain.findIt("#lockedLabel");
					lockLbl.show();
					lockLbl.findIt("#lockedUntil").text(msg);
				} else if (jqXHR.status === 417) {
					showTwoFactorPanel();
				} else if (jqXHR.status === 403) {
					if (msg === "loginfailed") {
						loginMain.findIt("#loginFailedLabel").show();
					} else if (msg === "accountinactive") {
						loginMain.findIt("#inactiveLabel").show();
					} else if (msg === "verifyaccount") {
						loginMain.findIt("#verifyLabel").show();
						loginMain.findIt("#resendEmailBtn").click(resendVerificationEmail);
					} else {
						genericError();
					}
				} else {
					genericError();
				}
				function genericError() {
					var infoLabel = loginMain.findIt("#infoLabel");
					if (errorThrown) {
						infoLabel.htmlSafe(errorThrown);
					} else {
						infoLabel.text(OUtils.t("An error occurred during authentication.  Please try again.", "Login.js.auth_error_occurred"));
					}
					infoLabel.show();
				}
				OUtils.hideWait();
			}
			postLogin(postData, loginError);
			return true;
		}
		
		function submitWithAuthCode(authCode, rememberDevice) {
			var form = loginMain.find("#loginForm");
			var postData = $(form[0]).find("input").serialize();
			if (authCode) {
				postData += "&authCode=" + authCode;
			}
			if (rememberDevice) {
				postData += "&deviceId=" + encodeURIComponent(getDeviceId()) + "&rememberDevice=true";
			}

			function authCodeLoginError(jqXHR, textStatus, errorThrown) {
				if (jqXHR.status === 423) {
					// account locked
					var msg = jqXHR.responseText;
					var lockLbl = loginMain.findIt("#authCodeLockedLabel");
					lockLbl.show();
					lockLbl.findIt("#authCodeLockedUntil").text(msg);
				} else {
					loginMain.findIt("#authCodeFailedLabel").show();
				}
				OUtils.hideWait();
			}
			postLogin(postData, authCodeLoginError);
			return true;
		}
	}

	function resendVerificationEmail() {
		OUtils.showWait();
		requirejs(["dwr/interface/SignUpRpc"], function(SignUpRpc) {
			SignUpRpc.resendConfirmationEmail(loginMain.findIt("#username").val(), {
				callback: function() {
					loginMain.findIt("#resendEmailBtn").hide();
					OUtils.hideWait();
					OUtils.showNote(OUtils.t("Please check your email and spam folders for our email and click the confirmation link inside.", "Login.js.check_email_for_confirmation"),
						{
							title: OUtils.t("Confirmation Email Sent", "Login.js.confirmation_email_sent"),
							modal: true
						});
				},
				errorHandler: function(err, exc) {
					OUtils.showError(err);
				}
			});
		});
	}
	
	function getDeviceId() {
		return DetectBrowser.getDeviceId();
	}
	
	$(function() {
		if (OUtils.getCookie('i18next')) {
			OUtils.setTranslationFlag(true);
			OUtils.i18nInitTest({ namespaces: ['shared'] }, function() {
				initLoginPage();
				OUtils.translateHtml($("html"));
			});
		} else {
			initLoginPage();
		}
	});
	
	return {
	};
});